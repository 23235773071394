import { css, styled, thd, up } from '@smooth-ui/core-sc';
import { NavLink } from 'react-router-dom';

export default styled(NavLink)`
  border-left: 3px solid transparent;
  color: ${thd('customMenuColor', '#54707c')};
  display: block;
  font-size: 20px;
  font-weight: 550;
  font-variation-settings: 'wght' 550;
  padding: 0.5em;
  ${props =>
    props.depth && props.depth > 1 && `padding-left: ${props.depth - 1}em;`}
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${thd('customMenuHighlightColor', '#000')};
    box-shadow: none !important;
  }

  &:focus,
  &.menu-active:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${thd('customMenuFocus', 'rgba(84,112,124,0.25)')}
      inset;
  }

  &.menu-active {
    border-left-color: ${thd('customMenuHighlightColor', '#54707c')};
    color: ${thd('customMenuHighlightColor', '#000')};
    font-weight: bold;
  }

  ${up(
    'lg',
    css`
      font-size: 16px;
      ${props =>
        // If it's the top level
        (!props.depth || props.depth < 2) &&
        css`
          border-left: 0 none;
          border-bottom: 5px solid transparent;
          margin-left: 0.7rem;
          margin-right: 0.7rem;
          padding: 0.25em 0.2em;
        `}

      &.menu-active {
        ${props =>
          // If it's the top level
          (!props.depth || props.depth < 2) &&
          css`
            &:after {
              content: '';
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 10px solid ${thd('primary', '#EC1C24')};
              position: absolute;
              bottom: 0;
              left: 50%;
              margin-left: -5px;
            }
          `}
        &:hover,&:focus {
          color: ${thd('customMenuHighlightColor', '#000')};
          box-shadow: none !important;
        }
      }
    `
  )}
`;
